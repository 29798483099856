export default [
  {
    path: "/",
    text: "Home",
    isExternal: false,
  },
  {
    path: "https://www.gofundme.com/f/harmonybaseballacademy",
    text: "Donate",
    isExternal: true,
  },
  {
    path: "/bio",
    text: "Meet the Coaches",
    isExternal: false,
  },
  {
    path: "/contact",
    text: "Contact",
    isExternal: false,
  },
  // {
  //   path: "/blog",
  //   text: "Blog",
  //   isExternal: false
  // },------------------------------------------removed from nav until corrected
  {
    path: "/partners",
    text: "Our Partners",
    isExternal: false,
  },
  {
    path: "/store",
    text: "Store",
    isExternal: false,
  },
]
